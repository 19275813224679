@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@500&display=swap");
body {
  margin: 0;
  font-family: "Raleway", serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: linear-gradient(to left, rgba(156, 156, 41, 0.717), rgb(37, 37, 37));
}

@media (max-width: 767px){
  body {
    background-image: linear-gradient(to bottom, rgba(156, 156, 41, 0.717), rgb(37, 37, 37));
  }
}
